@tailwind base;
@tailwind components;
@tailwind utilities;
::-webkit-scrollbar {
  width: 6px; /* Scroll bar genişliği */
}
body{
  overflow-x: hidden;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scroll bar yolu arka plan rengi */
}

::-webkit-scrollbar-thumb {
  background: #12af7b; /* Scroll bar başparmak rengi */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scroll bar başparmak hover rengi */
}
main {
  /* background-color: #1d2234; */
  /* background-color: #0b2040; */
  /* background-color: #38454d; */
  background-color: #000;


  /* z-index: -1; */
}
footer {
  /* background-color: #1d2234; */
  /* background-color: #0b2040; */
  /* background-color: #38454d; */
  background-color: #000;
}
p{
  /* color: #12af7b; */
  /* color: #32d88e; */
  color: rgba(255, 255, 255, 0.8);
}
.section-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  color: #ffffff;
}
p{
  font-size: 22px;
  font-weight: 400;
}
.home-bg {
  /* background-color: #1d2234; */
  /* background-color: #38454d; */
  background-color: #000;
  /* background-color: #0b2040; */

  background-image: url('../public/img/home.jpg') ;
  height: 100vh;
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;
  margin-top: -144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
}
@media (max-width:1500px) {
  .home-bg{
    background-size: contain;
    margin-top: -200px;

  } 
}
.text-area {
  margin-top: 7rem;
}
.home-text {
  font-family: "Poppins", sans-serif !important;
}
.home-text span {
  color: #25F220;
}
.home-text .light-bold {
  font-weight: 500;
}
.home-text-2  {
  font-family: "Poppins", sans-serif !important;
}
.home-text-2 span {
  color: #25F220;
}
.home-text-2 .bold {
  font-weight: 700;
}
.home-text .bold {
  font-weight: 700;
}
.home-bg h1 span {

}
.section-span {
  padding: 5px 40px;
  /* max-width: 150px; */
  width: fit-content;
  border-radius: 10px;
  background-color: #12af7b;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}
.coming-soon {
  padding: 5px 40px;
  /* max-width: 150px; */
  width: fit-content;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.section-img {
  border-radius: 10px;
  max-width: 600px;
  max-height: 400px;
}
.right-section {
  padding: 0px 0px 0px 4rem;
}
.left-section {
  padding: 0px 4rem 0px 0px;
}
.banner-area-1 {
  background:  linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)
  ), url('../src/img/bg.jpeg');
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: bottom center;
  height: 30vh;
  /* background-blend-mode: overlay; */
  /* filter: brightness(0.5); */
}
.banner-area-2 {
  background:  linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)
  ), url('../src/img/stadyum.jpg');
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;
  height: 30vh;
  /* background-blend-mode: overlay; */
  /* filter: brightness(0.5); */
}
.bg-special {
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-btn {
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  transition: 0.3s all linear; 
}
.login-btn:hover {
  /* transform: scale(1.1); */
  transition: 0.3s all linear; 
  border: 1px solid #12af7b;
  background-color: #12af7b;
  color: #fff;
}
.login-btn-black {
  padding: 5px 20px;
  border: 1px solid #000;
  border-radius: 10px;
  height: 40px;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
a{
  transition: 0.3s all linear; 
}
a:hover {
  color: #12af7b;
  transition: 0.3s all linear; 
}
.bg-special img {
 max-width: 100%;
}
@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.rotating {
  animation: rotate 5s linear infinite;
}
@media screen and (max-width: 1320px) {
  .section-img{
    max-width: 100%;
  }
}
@media screen and (max-width: 1138px) {
  .text-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
  }
  .home-bg {
    background-image: url('../public/img/mobile-home.jpg') ;
    background-position: center center;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -144px;
  }
  
}
@media screen and (max-width: 767px) {
  .home-bg {
    margin-top: -112px;
    background-size: cover;
  }
  .section-img{
    max-width: 100%;
  }
  .right-section {
    padding: 15px 0px 0px 0rem;
  }
  .left-section {
    padding: 0px 0rem 15px 0px;
  }
  .banner-area-1 {
    text-align: center !important;
  }
  .section-title {
    font-size: 24px;
    line-height: 30px;
  }
  p {
    font-size: 16px;
  }
  .coming-soon {
    padding: 5px 20px;
  }
  .section-span {
    padding: 5px 20px;
  }
  .text-area {
    margin-top: 4rem;
    height: 82vh;
  }
}